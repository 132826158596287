@tailwind base;
@tailwind components;
@tailwind utilities;

.table-cols {
  width: 180px;
}

@layer base {
  body {
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
  }
}

/* animations and transitions */

/* grow-business section on homepage */
.grow-business .card img {
  animation: heartBeat1 3s linear 0s infinite;
}

@keyframes heartBeat1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(0.85);
    opacity: 1;
  }
  35% {
    transform: scale(1.3);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.swiper_container {
  position: relative;
  overflow: visible;
}

.swiper_container::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 50px;
  width: 80%;
  height: 80%;
  background: url("./assets/images/livechatbg1.svg") no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center bottom;
  overflow: visible; /* Add this line to ensure the pseudo-element's overflow is visible */
}

.swiper_container::before {
  content: "";
  position: absolute;
  top: -50px;
  width: 150px;
  right: 20px;
  height: 200px;
  background-color: red;
  background: url("./assets/images/livechatbg-3.svg") no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center bottom;
}

.getstarted button {
  transition: ease 0.5s;
}
.getstarted button:hover {
  outline: 2px solid #7055da;
  outline-offset: 5px;
  transition: ease-in 0.5s;
}

img {
  transition: ease-in-out 0.5s;
  cursor: pointer;
}

.copy-icon {
  transform: scale(1);
  margin-top: 1rem;

  transition: ease-out 0.5s;
}

img:hover {
  transform: scale(1.1);

  transition: ease-out 0.5s;
}

.pricing-card .card:hover {
  transform: scale(1.05);
}

/* end of animations and transitions */

/* faq animations */

.faq-content {
  height: 0;
  overflow: hidden;
  transition: all 1s ease;
}

.faq-active {
  height: auto;
}

.pricing-faq {
  height: auto;
}

.faqq {
  height: 0;
  max-height: 0;
  overflow: hidden;
}

/* if link is active */
.active {
  text-decoration-thickness: 3.5px;
  text-decoration-color: #7055da;
  text-underline-offset: 8px;
  text-decoration-line: underline;
}

.react-multi-carousel-track {
  align-items: center;
}

@layer components {
  /* clients testimonials and slider */

  .translate-left {
    transition: opacity 1s ease-in-out;
  }

  .translate-left.translated {
    opacity: 0;
  }

  /* ends animation of testimonials */
  .slider-imgs img {
    cursor: pointer;
  }

  .client-arrow {
    position: relative;
  }

  .client-arrow:after {
    position: absolute;
    bottom: 20px;

    left: 50%;
    margin-left: -20px;
    content: "";
    display: block;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    filter: drop-shadow(0px 4px 15px #d4d4d4);
  }

  .animate-card {
    position: relative;
    z-index: 0;

    border-radius: 10px;
    overflow: hidden;
    padding: 2rem;
  }

  .animate-card::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;

    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#fff, #fff);
    animation: rotate 4s linear infinite;
  }

  .animate-card::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: white;
    border-radius: 5px;
    background-color: #5b40c5;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  .wiggle {
    animation: wiggle 1s alternate-reverse infinite;
  }

  @keyframes wiggle {
    from {
      transform: rotate(-15deg);
    }

    to {
      transform: rotate(-45deg);
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7055da;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5b46af;
  }

  /* YourComponent.css */

  .animate {
    animation: slideUpDown 1.5s ease-in-out alternate; /* Adjust the properties as needed */
  }
  .animate-2 {
    animation: slideUpDown 1.4s ease-in-out alternate; /* Adjust the properties as needed */
  }

  @keyframes slideUpDown {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    50% {
      opacity: 0.5;
      transform: translateY(-10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  /* ======== */

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin-top: 4rem;
    border-radius: 10px !important;
  }

  .react-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .testimonials-slider {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  }

  /* ====== */
  .main-table table tbody td {
    border-left: 1px solid #e5e7eb;
  }

  .pricing-card .card {
    width: 85%;
    transition: ease 0.5s;
  }

  .pricing-card .card:hover > p {
    color: white;
  }
  .pricing-card .card:hover > span {
    color: white;
  }
  .pricing-card .card > ul {
    color: #021442;
  }
  .pricing-card .card:hover > ul {
    color: white;
  }
  .pricing-card .card button {
    margin-top: 1rem;
    background-color: #7055da;
    color: white;
  }
  .pricing-card .card:hover button {
    color: #7055da;
    background-color: white;
  }
  .pricing-card .card button:hover {
    color: #7055da;
    background-color: rgb(231, 229, 229);
  }
  .pricing-card .card:hover {
    background-color: #7055da;
    cursor: pointer;
    transition-duration: 500ms;
  }

  .anmate-shake {
    background-color: #5b46af !important;
  }

  .accordion {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 1s ease-in-out, opacity 1s ease-in-out,
      visibility 1s ease-in-out;
    opacity: 0;
  }

  .active-accordion {
    max-height: 600px;
    transition: max-height 1s ease, opacity 1s ease, visibility 1s ease;
    opacity: 1;
  }

  /* .accordion-container {
    position: relative;
    max-height: 1300px; 
    overflow: hidden;
  }

  .accordion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out,
      visibility 0.5s ease-in-out;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  .active-accordion {
    max-height: 600px;
    transition: max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
    opacity: 1;
    visibility: visible;
  } */

  .active-accordion-line {
    border-left: 10px solid #7055da;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .accordion-line {
    border-left: 10px solid #ededf0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .about-bar {
    background: url("./assets/images/info-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .location {
    position: relative;
  }

  .location::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
    border-radius: 20px;
  }

  .testimonial {
    fill: #fff;
    filter: drop-shadow(0px 30px 40px rgba(212, 217, 232, 0.2));
  }

  @media screen and (min-width: 767px) {
    .mobile-logo {
      display: none;
    }
    .mobile-menu {
      display: none;
    }
  }

  @media screen and (min-width: 757px) {
    .home-header-text {
      line-height: 63px !important;
    }
  }

  /* ham burger menu */
  @media screen and (max-width: 767px) {
    .myheader {
      /* padding: 0px !important; */
      background-color: white;
      /* height: 45vh; */
      transition: all;
      transition-duration: 1s;
      background-color: #5b40c5;
    }

    .mobile-logo {
      width: 120px;
      margin-top: 5px;
    }

    /* .mobile-menu {
      padding: 0 1.8rem;
    } */

    .faq-active {
      height: auto;
      transition: all 1s ease-in-out;
    }

    nav {
      position: relative;
    }
    .main-menu.show-menu .menus {
      transition: all;
      transition-duration: 1s;
      background-color: white;
      display: flex;
      flex-direction: column;
      position: absolute;
      min-height: 50vh;
      justify-content: flex-start;
      top: 0;
      left: 0;
      right: 0;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
    .main-menu.show-menu ul {
      width: 100%;

      display: flex;
      flex-direction: column;
      height: 50%;
      padding: 0 2rem;
    }
    .main-menu.show-menu .menus .menu-btns {
      display: flex;
      flex-direction: column;

      width: 100%;
      margin-left: 3rem;
      gap: 1rem;
      padding: 1rem 0;
    }

    .close-menu-btn svg {
      position: absolute;
      right: 10px;
      top: 2rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .close-menu-btn svg {
    right: 20px;
  }
  .pricing-faq {
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  .testimonials-text {
    height: 240px !important;
    background: #000;
  }
}

@media screen and (min-width: 1600px) {
  nav {
    max-width: 1750px;
  }
  .main-div {
    max-width: 1750px;
    margin: auto;
  }
}
